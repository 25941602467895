import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Civil Engineering Construction and Graphics`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/civil-engineering-construction-and-graphics-books/#:~:text=Download%3A%20Building%20construction%20Metric%20Vol.%201%20By%20W.B.%20Mckay"
      }}>{`Download: Building construction Metric Vol. 1 By W.B. Mckay`}</a></p>
    <h2>{`Lecture Slides`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/2-_Types_of_Stone_Brick_and_block_masonry-Compatibility-Mode.pdf"
      }}>{`Types_of_Stone_Brick_and_block_masonry `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/6.concrete.pdf"
      }}>{`Concrete`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/13-Services.ppt"
      }}>{`Services`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/dampness-in-buildings.pdf"
      }}>{`Dampness in buildings`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Finishing.pptx"
      }}>{`Finishing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/formwork.pdf"
      }}>{`Formwork`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/introduction-to-prestressed-concrete.ppt"
      }}>{`Introduction to prestressed concrete`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/introductionsite-selection-and-orientation.pdf"
      }}>{`Introduction,site selection and orientation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/movement-joints.pdf"
      }}>{`Movement joints`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/reinforcement-sheet.pdf"
      }}>{`Reinforcement sheet`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/services-in-buildings.pdf"
      }}>{`Services in buildings`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/stairs.pdf"
      }}>{`Stairs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/types-of-drawings-in-civil-engineering-projects.pdf"
      }}>{`Types of drawings in civil engineering projects`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/types-of-stone-masonry.pptx"
      }}>{`Types of stone masonry`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      